<template>
  <el-select
    v-model="value"
    filterable
    size="small"
    :style="priorityColor"
    placeholder="Select"
    value-key="id"
    @change="$emit('change-status', value)"
  >
    <el-option
      v-for="prior in priorities"
      :key="prior.id"
      :label="prior.title"
      :value="prior"
    />
  </el-select>
</template>

<script>
export default {
  name: 'Priority',
  props: {
    priority: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      value: null,
      priorities: [
        { id: 1, title: 'Low', color: '#67c23a' },
        { id: 2, title: 'Medium', color: '#e6a23c' },
        { id: 3, title: 'High', color: '#f56c6c' },
      ],
    };
  },
  computed: {
    priorityColor() {
      if (this.priority) {
        return this.checkedPriority();
      }

      return this.defaultStyles();
    },
  },
  methods: {
    checkedPriority() {
      this.value = this.priorities.find(p => p.id === this.priority.id);

      return { 'background-color': `${this.value.color}` };
    },
    defaultStyles() {
      this.value = null;

      return 'background-color: #EBEEF5';
    },
  },
};
</script>
